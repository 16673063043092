<template>
  <div class="overall">
    <div class="title">
      <h2>Overall</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="filter">
              <el-row :gutter="10">
                <el-form-item label="Affiliate ID" label-width="1.65rem">
                  <el-col :xs="10" :sm="6" :md="6" :lg="7" :xl="6">
                    <group-select
                      v-model="filter.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      style="width:2.4rem;"
                    >
                    </group-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="Affiliate status" label-width="2rem">
                  <el-col :xs="8" :sm="6" :md="6" :lg="7" :xl="6">
                    <el-select v-model="filter.status" placeholder="active" style="width:2.4rem;">
                      <el-option
                        v-for="item in statusOption"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="Affiliate trafficType" label-width="2.6rem">
                  <el-col :xs="10" :sm="6" :md="6" :lg="7" :xl="6">
                    <el-select
                      v-model="filter.trafficType"
                      placeholder="请选择"
                      style="width:2rem;"
                    >
                      <el-option
                        v-for="item in payoutTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="Affiliate name" label-width="2rem">
                  <el-col :xs="8" :sm="6" :md="6" :lg="8" :xl="6">
                    <el-input
                      v-model="filter.affiliateName"
                      style="width:3rem;"
                      placeholder="your searching aff regex here"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="12">
                    <el-button type="primary" @click="checkList(1)">In Browser</el-button>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="12">
                    <el-button type="primary" icon="el-icon-plus" @click="addClick"
                      >Create</el-button
                    >
                  </el-col>
                </el-form-item>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <div>
              <el-table
                v-loading="loading"
                border
                stripe
                :data="dataList"
                style="width: 100%;margin-top:20px;"
                height="70vmin"
                highlight-current-row
              >
                <el-table-column label="Id" align="center" min-width="60" fixed>
                  <template slot-scope="scope">
                    <span @click="detailClick(scope.row)" class="cor337ab7">{{
                      scope.row.id
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Affiliate Name"
                  prop="company"
                  align="center"
                  min-width="130"
                  fixed
                >
                  <template slot-scope="scope">
                    <span @click="detailClick(scope.row)" class="cor337ab7">{{
                      scope.row.company
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Affiliate Status"
                  prop="status"
                  align="center"
                  min-width="130"
                ></el-table-column>
                <el-table-column
                  label="Postback Url"
                  prop="postbackUrl"
                  min-width="120"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="Event Pb Url"
                  prop="eventPbUrl"
                  min-width="120"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="Reject Pb Url"
                  prop="rejectPbUrl"
                  min-width="120"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="Description"
                  prop="description"
                  align="center"
                  min-width="100"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="Token Code"
                  prop="code"
                  align="center"
                  min-width="110"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="Adjusted"
                  prop="adjusted"
                  align="center"
                  min-width="100"
                ></el-table-column>
                <el-table-column
                  label="Delay time"
                  prop="outHourAdjust"
                  align="center"
                  min-width="100"
                ></el-table-column>
                <el-table-column
                  label="ClickLimited"
                  prop="crBlackLimited"
                  align="center"
                  min-width="110"
                ></el-table-column>
                <el-table-column
                  label="BlackByCrClicks"
                  prop="crBlackClicks"
                  align="center"
                  min-width="125"
                ></el-table-column>
                <el-table-column
                  label="TrafficCountry"
                  prop="trafficCountry"
                  align="center"
                  min-width="125"
                ></el-table-column>
                <el-table-column
                  label="MMP"
                  prop="mmp"
                  align="center"
                  min-width="125"
                ></el-table-column>
                <el-table-column
                  label="Track Domain"
                  prop="trackDomain"
                  align="center"
                  min-width="120"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="AsyncTag"
                  min-width="140"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.asyncTag === 0">关闭</span>
                    <span v-else-if="scope.row.asyncTag === 1">开启</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Event Pb Enable"
                  min-width="140"
                  align="center"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.eventPbEnable === 0">关闭</span>
                    <span v-else-if="scope.row.eventPbEnable === 1">开启</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="operator"
                  prop="createBy"
                  align="center"
                  min-width="100"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                  label="operation time"
                  prop="createTime"
                  align="center"
                  min-width="130"
                  show-overflow-tooltip
                ></el-table-column>
                <el-table-column label="Option" align="center" min-width="180">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="updateAffiliateClick(scope.row)" size="small"
                      >Update</el-button
                    >
                    <el-button @click="openAffUser(scope.row)" size="small">UserAff</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <pagination
                class="block pagePanel"
                :pageTotal="total"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :pageSize="pageSize"
                :currentPage="currentPage"
              ></pagination>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 模块框 -->
    <el-dialog
      :title="dialogType === 'new' ? 'Affiliate' : 'Update'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <el-form
        label-position="right"
        label-width="130px"
        ref="addAffiliate"
        :model="affiliate"
        :rules="rules"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Name:" prop="affiliateName">
              <el-input v-model="affiliate.affiliateName" placeholder="Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Description:" prop="description">
              <el-input v-model="affiliate.description" placeholder="description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Postback Url:">
              <el-input
                v-model="affiliate.postbackUrl"
                placeholder="postback url here..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="eventPbUrl:">
              <el-input
                v-model="affiliate.eventPbUrl"
                placeholder="event Pb url here..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="rejectPbUrl:">
              <el-input
                v-model="affiliate.rejectPbUrl"
                placeholder="event Pb url here..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Traffic Type:" prop="trafficType">
              <el-select
                v-model="affiliate.trafficType"
                filterable
                placeholder="Traffic Type"
                style="width:100%"
              >
                <el-option
                  v-for="item in trafficType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Level:">
              <el-select
                v-model="affiliate.level"
                filterable
                placeholder="Level"
                style="width:100%"
              >
                <el-option
                  v-for="item in levelOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="Adjusted Enable:" label-width="130px" prop="adjusted">
              <el-select
                v-model="affiliate.adjusted"
                filterable
                placeholder="Adjusted Enable"
                style="width:100%"
              >
                <el-option
                  v-for="item in offerMonitor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Track Domain:">
              <el-input
                v-model="affiliate.trackDomain"
                placeholder="track domain here..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="ClickLimited:" label-width="130px" prop="crBlackLimited">
              <el-select v-model="affiliate.crBlackLimited" filterable style="width:100%">
                <el-option
                  v-for="item in clickLimiteOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Out Hour Adjust:" label-width="130px">
              <el-input
                v-model="affiliate.outHourAdjust"
                placeholder="outHourAdjust url here..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="BlackByCrClicks:" label-width="130px" prop="crBlackClicks">
              <el-input v-model="affiliate.crBlackClicks" style="width:100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Traffic Tag:" prop="trafficTag">
              <el-select
                v-model="affiliate.trafficTag"
                clearable
                filterable
                placeholder="status"
                style="width:100%"
              >
                <el-option
                  v-for="item in tagOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1" v-if="dialogType === 'new'">
            <el-form-item label="Authorised:" prop="bindAccountId" required>
              <el-select v-model="affiliate.bindAccountId" clearable filterable style="width:100%">
                <el-option
                  v-for="item in bindAccountList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1" v-if="dialogType !== 'new'">
            <el-form-item label="Status:" prop="status">
              <el-select v-model="affiliate.status" filterable placeholder="status">
                <el-option
                  v-for="item in statusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="TrafficCountry:" prop="trafficCountry">
              <el-input v-model="affiliate.trafficCountry"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="20" :offset="1">
            <el-form-item label="MMP:" prop="mmp">
              <el-select v-model="affiliate.mmp" clearable filterable style="width:100%">
                <el-option
                  v-for="item in mmpOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Async Tag">
              <el-select v-model="affiliate.asyncTag" style="width:100%">
                <el-option
                  v-for="item in numberSwitchOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Event Pb Enable">
              <el-select v-model="affiliate.eventPbEnable" style="width:100%">
                <el-option
                  v-for="item in numberSwitchOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitClick()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="userDialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-transfer
        filterable
        :titles="['Authorizable', 'Authorised']"
        v-model="affUserList"
        :data="allUser"
      ></el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="authAffUser()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getAffiliateList,
    getAffUserList,
    insertAffiliate,
    updateAffiliate,
    updateAffUser,
  } from 'api/affiliate/overall';
  import { getBindAccountListApi } from 'api/account/index';

  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';
  import { getAllTags } from '@/api/affiliate/tag';
  import { getUserList } from 'api/user';
  import { mapActions, mapState } from 'vuex';
  import GroupSelect from '@/components/GroupSelect';

  var clickTimer = null;

  const defaultAffiliate = {
    affiliateName: '',
    description: '',
    level: '',
    trafficType: '',
    postbackUrl: '',
    trackDomain: '',
    adjusted: '',
    outHourAdjust: '',
    status: '',
    crBlackLimited: 'CLOSE',
    crBlackClicks: '',
    bindAccountId: '',
    mmp: 'no_MMP',
    asyncTag: 1,
    eventPbEnable: 0,
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        loading: false,
        pageSize: 20,
        currentPage: 1,
        total: null,
        dialogVisible: false,
        dialogType: 'new',
        affiliate: Object.assign({}, defaultAffiliate),

        filter: {
          affiliateId: null,
          status: 'active',
          payoutType: null,
          affiliateName: null,
        },
        statusOption: [
          {
            label: 'ACTIVE',
            value: 'active',
          },
          {
            label: 'PENDING',
            value: 'pending',
          },
          {
            label: 'PAUSED',
            value: 'paused',
          },
        ],
        status: [
          {
            label: 'ACTIVE',
            value: 'ACTIVE',
          },
          {
            label: 'PENDING',
            value: 'PENDING',
          },
        ],
        levelOption: [
          {
            label: 'Direct',
            value: 50,
          },
          {
            label: 'In-app',
            value: 40,
          },
          {
            label: 'Network',
            value: 30,
          },
          {
            label: 'MediaBuy',
            value: 20,
          },
          {
            label: 'Incent',
            value: 10,
          },
        ],
        trafficType: [
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
        ],
        offerMonitor: [
          {
            label: 'DISABLE',
            value: 'DISABLE',
          },
          {
            label: 'ENABLE',
            value: 'ENABLE',
          },
        ],
        payoutTypeOptions: [
          {
            label: 'ALL',
            value: null,
          },
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
        ],
        clickLimiteOptions: [
          {
            label: 'OPEN',
            value: 'OPEN',
          },
          {
            label: 'CLOSE',
            value: 'CLOSE',
          },
        ],
        dataList: [],
        tagOption: [],
        bindAccountList: [],
        userDialogVisible: false,
        affUserList: [],
        allUser: [],
        affiliateId: '',
        mmpOptions: [
          {
            label: 'MMP only',
            value: 'MMP only',
          },
          {
            label: 'MMP',
            value: 'MMP',
          },
          {
            label: 'no_MMP',
            value: 'no_MMP',
          },
        ],
        numberSwitchOptions: [
          {
            value: 0,
            label: '关闭',
          },
          {
            value: 1,
            label: '开启',
          },
        ],
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      rules() {
        const crBlackClicksRule =
          this.affiliate.crBlackLimited === 'OPEN'
            ? [
                {
                  required: true,
                  message: 'please write BlackByCrClicks',
                  trigger: ['change', 'blur'],
                },
              ]
            : [];
        return {
          affiliateName: [
            {
              required: true,
              message: 'please write name',
              trigger: 'blur',
            },
          ],
          trafficType: [
            {
              required: true,
              message: 'please select traffic type',
              trigger: 'blur',
            },
          ],
          adjusted: [
            {
              required: true,
              message: 'please select adjusted',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'please write description',
              trigger: 'blur',
            },
          ],
          status: [
            {
              required: true,
              message: 'please select status',
              trigger: 'blur',
            },
          ],
          crBlackClicks: crBlackClicksRule,
          bindAccountId: {
            required: true,
            message: 'please select bindAccountId',
            trigger: 'blur',
          },
          trafficTag: {
            required: true,
            message: 'please select trafficTag',
            trigger: 'blur',
          },
          trafficCountry: {
            required: true,
            message: 'please select trafficCountry',
            trigger: 'blur',
          },
          mmp: {
            required: true,
            message: 'please select mmp',
            trigger: 'blur',
          },
        };
      },
    },
    mounted() {
      this.getAffiliateList();
      this.checkList();
      this.getTagList();
      this.getBindAccountList();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      checkList(page) {
        this.loading = true;
        if (page) {
          this.currentPage = page;
        }
        let param = {
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (param.affiliateIds) {
          param.affiliateIds = param.affiliateIds.join(',');
        }
        getAffiliateList(param).then((response) => {
          if (response.code === 200) {
            this.loading = false;
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.loading = false;
            this.$message.error(response.message);
          }
        });
      },
      addClick() {
        this.affiliate = Object.assign({}, defaultAffiliate);
        delete this.affiliate.status;
        this.dialogType = 'new';
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.addAffiliate.clearValidate();
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.checkList();
      },
      updateAffiliateClick(row) {
        let data = row;
        data.affiliateName = row.company;
        this.affiliate = Object.assign({}, defaultAffiliate, row);
        this.dialogType = 'edit';
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.addAffiliate.clearValidate();
        });
      },
      handleClose() {
        this.dialogVisible = false;
      },
      submitClick() {
        this.$refs.addAffiliate.validate((valid) => {
          if (valid) {
            if (this.dialogType === 'new') {
              insertAffiliate({ ...this.affiliate }).then((response) => {
                if (response.code == 200) {
                  this.$message({
                    message: 'Add Update',
                    type: 'success',
                  });
                  this.checkList();
                  this.dialogVisible = false;
                } else {
                  this.$message.error('Add Error:' + response.message);
                }
              });
            } else if (this.dialogType === 'edit') {
              updateAffiliate({ ...this.affiliate }).then((response) => {
                if (response.code === 200) {
                  this.$message({
                    message: 'Update Success',
                    type: 'success',
                  });
                  this.checkList();
                  this.dialogVisible = false;
                } else {
                  this.$message.error('Update Error:' + response.message);
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(() => {
          this.$nextTick(() => {
            console.log(row, 'row');
            const { href } = this.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.id,
                affiliateName: row.company,
                status: row.status,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      getTagList() {
        getAllTags()
          .then((resp) => {
            if (resp.code === 200) {
              this.tagOption = resp.result?.map((item) => ({
                label: item.trafficTag,
                value: item.trafficTag,
              }));
            } else {
              this.tagOption = [];
            }
          })
          .catch(() => {
            this.tagOption = [];
          });
      },
      getBindAccountList() {
        getBindAccountListApi()
          .then((resp) => {
            if (resp.code === 200) {
              this.bindAccountList = resp.result?.map((item) => ({
                label: item.uname,
                value: item.id,
              }));
            } else {
              this.bindAccountList = [];
            }
          })
          .catch(() => {
            this.bindAccountList = [];
          });
      },
      async getAllUser() {
        const { code, result = [] } = await getUserList();
        if (code) {
          this.allUser = result.map((item) => ({
            key: item.id,
            label: item.uname,
            title: item.uname,
          }));
        }
      },
      async getAffUsers(affiliateId) {
        const { code, result = [] } = await getAffUserList(affiliateId);
        if (code === 200) {
          this.affUserList = result.map((item) => item.id);
        } else {
          this.$message.error('Failed to get authorized list');
        }
      },
      openAffUser(row) {
        this.affiliateId = row.id;
        this.getAllUser();
        this.getAffUsers(row.id);
        this.userDialogVisible = true;
      },
      async authAffUser() {
        const params = {
          affiliateId: this.affiliateId,
          accountList: this.affUserList,
        };
        const { code, message } = await updateAffUser(params);
        if (code === 200) {
          this.$message.success({
            message: 'Update Success',
            type: 'success',
          });
          this.userDialogVisible = false;
        } else {
          this.$message.error('Update Error:' + message);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  ::v-deep .el-transfer {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-transfer-panel {
      width: 288px !important;
    }
  }
</style>
